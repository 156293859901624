// ChatPage.jsx
import React, { useState, useEffect } from 'react';
import { Layout } from 'antd';
import ConversationList from '../components/Conversations/ConversationList';
import MessageArea from '../components/Messages/MessagesArea';
import { useSocket } from '../Hooks/useSocket';
const { Sider, Content } = Layout;

// --- Dummy Data ---
const DUMMY_CONVERSATIONS = [
  {
    id: '1',
    name: 'Alice',
    lastMessage: 'Hey there!',
    avatar: 'https://i.pravatar.cc/150?img=1',
  },
  {
    id: '2',
    name: 'Bob',
    lastMessage: 'See you soon.',
    avatar: 'https://i.pravatar.cc/150?img=2',
  },
  {
    id: '3',
    name: 'Charlie',
    lastMessage: 'All good!',  
    avatar: 'https://i.pravatar.cc/150?img=3',
  },
];

const DUMMY_MESSAGES = {
  '1': [
    { id: 'm1', senderId: 'Alice', content: 'Hello!', timestamp: '2023-01-01T10:00:00Z' },
    { id: 'm2', senderId: 'currentUser', content: 'Hi Alice, how are you?', timestamp: '2023-01-01T10:01:00Z' },
    { id: 'm3', senderId: 'Alice', content: 'I am great, thanks!', timestamp: '2023-01-01T10:02:00Z' },
  ],
  '2': [
    { id: 'm4', senderId: 'Bob', content: 'Hey, are we still meeting later?', timestamp: '2023-01-02T09:15:00Z' },
    { id: 'm5', senderId: 'currentUser', content: 'Yes! Let’s do it.', timestamp: '2023-01-02T09:16:00Z' },
    { id: 'm6', senderId: 'Bob', content: 'Great, see you soon.', timestamp: '2023-01-02T09:17:00Z' },
  ],
  '3': [
    { id: 'm7', senderId: 'Charlie', content: 'All is good here.', timestamp: '2023-01-03T11:25:00Z' },
    { id: 'm8', senderId: 'currentUser', content: 'Awesome, talk later!', timestamp: '2023-01-03T11:26:00Z' },
  ],
};

function ChatPage() {
  const { socket, isConnected } = useSocket();
  const [conversations, setConversations] = useState(DUMMY_CONVERSATIONS);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [messages, setMessages] = useState([]);

  // Simulate fetching conversation data
  useEffect(() => {
    if (selectedConversation && socket) {
      socket.emit("joinConversation", { conversationId: selectedConversation.id });

      // Listen for history when joining a conversation
      socket.on("conversationHistory", (history) => {
        setMessages(history);
      });

      return () => {
        socket.off("conversationHistory"); // Cleanup listener
      };
    }
  }, [selectedConversation, socket]);

  // Handle receiving messages
  useEffect(() => {
    if (socket) {
      socket.on("receiveMessage", (newMessage) => {
        setMessages((prev) => [...prev, newMessage]);
      });

      return () => {
        socket.off("receiveMessage");
      };
    }
  }, [socket]);

  const handleSelectConversation = (conversation) => {
    setSelectedConversation(conversation);
  };

  const handleSendMessage = (messageContent) => {
    if (!selectedConversation || !socket) return;

    const newMsg = {
      conversationId: selectedConversation.id,
      senderId: "currentUser",
      text: messageContent,
    };

    socket.emit("sendMessage", newMsg); // Send message via WebSocket
  };

  return (
    <Layout className="min-h-screen">
      <Sider width={300} className="bg-white border-r">
        <ConversationList
          conversations={conversations}
          selectedConversation={selectedConversation}
          onSelectConversation={handleSelectConversation}
        />
      </Sider>
      <Content className="bg-gray-50">
        <MessageArea
          conversation={selectedConversation}
          messages={messages}
          onSendMessage={handleSendMessage}
        />
      </Content>
    </Layout>
  );
}

export default ChatPage;

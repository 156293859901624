// ConversationList.jsx
import React from 'react';
import { Avatar } from 'antd';

function ConversationList({ conversations, selectedConversation, onSelectConversation }) {
  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Conversations</h2>
      <ul>
        {conversations.map((conv) => (
          <li
            key={conv.id}
            onClick={() => onSelectConversation(conv)}
            className={`flex items-center p-3 rounded-lg cursor-pointer mb-2 transition-colors duration-200 hover:bg-gray-100 ${
              selectedConversation && selectedConversation.id === conv.id ? 'bg-gray-200' : ''
            }`}
          >
            <Avatar src={conv.avatar} size="large" className="mr-4" />
            <div>
              <p className="font-semibold text-lg">{conv.name}</p>
              <p className="text-sm text-gray-500 truncate" style={{ maxWidth: 200 }}>
                {conv.lastMessage}
              </p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ConversationList;
